<div class="containt-header" [ngClass]="{'xs-view':responsive.isXsView}" #header>

  <div class="containt-burger" [ngClass]="{'xs-view':responsive.isXsView}" (click)="openOrCloseMenu()">
    <img src="assets/images/burger-{{isOpenMenu}}.png" alt="icon menu">
  </div>

  <div class="containt-logo-title">
    <div class="containt-logo">
      <a [routerLink]="'/home'"><img src="{{urlLogo}}" alt="logo farms"></a>
    </div>
  </div>

  <app-menu
    [ngClass]="{'xs-view':responsive.isXsView && !isOpenMenu, 'horizontal': isOpenMenu && responsive.isXsView, 'anime-menu': isOpenMenu && responsive.isXsView}"
    [menus]="menus" [isOpen]="isOpenMenu" (closeModal)="closeModal($event)"></app-menu>

  <app-language [dropdown]="true"></app-language>


</div>