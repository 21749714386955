import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IItemPolygon } from '../../models/item-polygon.model';
import { ResponsiveService } from '../../services/responsive.service';

/**
 * @name PolygonCardComponent
 * @description permet la gestion de l'affichage de texte dans un polygone avec le propriete  clip-path css
 * qui lui donne cette forme particulière. Gestion du responsive
 */
@Component({
  selector: 'app-polygon-card',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './polygon-card.component.html',
  styleUrl: './polygon-card.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class PolygonCardComponent {

  @Input() data!: IItemPolygon;

  constructor(protected responsive: ResponsiveService) { }
}
