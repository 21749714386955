import { Injectable } from '@angular/core';
import { TranslateService, TranslateLoader } from '@ngx-translate/core';
import { Observable, Subject, of } from 'rxjs';



@Injectable()
export class AppTranslationService {

  private onLanguageChanged = new Subject<string>();
  languageChanged$ = this.onLanguageChanged.asObservable();

  constructor(private translate: TranslateService) {
    this.addLanguages(['en', 'fr', 'es']);
    this.setDefaultLanguage('fr');
  }

  addLanguages(lang: string[]): void {
    this.translate.addLangs(lang);
  }

  setDefaultLanguage(lang: string): void {
    this.translate.setDefaultLang(lang);
  }

  getDefaultLanguage(): string {
    return this.translate.defaultLang;
  }

  getBrowserLanguage(): string | undefined {
    return this.translate.getBrowserLang();
  }

  getCurrentLanguage(): string {
    return this.translate.currentLang;
  }

  getLoadedLanguages(): string[] {
    return this.translate.langs;
  }

  useBrowserLanguage(): string | undefined {
    const browserLang = this.getBrowserLanguage();

    if (browserLang!= null && browserLang.match(/en|fr|es/)) {
      this.changeLanguage(browserLang);
      return browserLang;
    }
    return browserLang
  }

  useDefaultLangage() {
    return this.changeLanguage(null);
  }

  changeLanguage(language: string | undefined | null) {
    if (!language || language == null) {
      language = this.getDefaultLanguage();
    }

    if (language !== this.translate.currentLang) {
      setTimeout(() => {
        const lngNotNull: string = language == null ? this.getDefaultLanguage() : language;
        this.translate.use(lngNotNull);
        this.onLanguageChanged.next(lngNotNull);
      });
    }

    return language;
  }

  getTranslation(key: string | Array<string>, interpolateParams?: object): string | any {
    return this.translate.instant(key, interpolateParams);
  }

  getTranslationAsync(key: string | Array<string>, interpolateParams?: object): Observable<string | any> {
    return this.translate.get(key, interpolateParams);
  }

}
