import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IIndicator } from '../../models/indicator.model';
import { IItemPolygon } from '../../models/item-polygon.model';
import { ResponsiveService } from '../../services/responsive.service';
import { Constants } from '../../tools/constants';
import { IndicatorOfferingComponent } from '../indicator-offering/indicator-offering.component';
import { PolygonCardComponent } from '../polygon-card/polygon-card.component';

/**
 * @name OfferingComponent
 * @description gestion de l'affichage de la page Nos offres avec l'injection des composants suivant:
 * IndicatorOfferingComponent => pour un affichage générique des indicateurs super importants Farms
 * PolygonCardComponent => pour mettre en avant les indicateurs dans un style polygonal
 * ♡ ♡ ♡ Et parce que ça fait plaisir à Vanessa que je fasse des commentaires sur les composants ♡ ♡ ♡
 */
@Component({
  selector: 'app-offering',
  standalone: true,
  imports: [CommonModule, TranslateModule, IndicatorOfferingComponent, PolygonCardComponent],
  templateUrl: './offering.component.html',
  styleUrl: './offering.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class OfferingComponent {

  indicators: IIndicator[] = Constants.indicators;
  itemsPolygons: IItemPolygon[][] = Constants.itemsPolygons;

  constructor(protected responsive: ResponsiveService) { }
}
