import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IItemHistory } from '../../models/item-history.model';
import { ResponsiveService } from '../../services/responsive.service';
import { Constants } from '../../tools/constants';
import { ItemHistoryComponent } from '../item-history/item-history.component';
import { IItemNavigation } from '../../models/item-navigation.model';
import { FarmsNavigationComponent } from '../farms-navigation/farms-navigation.component';
import { GlobeOptions, NgxGlobeComponent } from '@omnedia/ngx-globe';
import { IPaysClient } from '../../models/pays-client.model';

/**
 * @name AboutUsComponent
 * @description composant qui permet la gestion de l'écran About us avec l'injection du composant
 * ItemHistoryComponent, cela permet d'afficher les données en bouclant sur ce composant qui contient
 * image text. Permet aussi la gestion de la navigation des anchors aavec un effet smooth
 */
@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [CommonModule, TranslateModule, ItemHistoryComponent, FarmsNavigationComponent,NgxGlobeComponent],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class AboutUsComponent {

  itemsHistory: IItemHistory[] = Constants.detailsHistory;
  pathPresence: string = Constants.pathPresence;
  shadow: string = Constants.shadow;
  finalHistory: string = Constants.finalHistory;
  scrollUp: string = Constants.scrollUp;
  
  globeOptions: GlobeOptions = {
    markers: [
      {
        location: [-1.7799014999999998, -78.131592],  // Latitude et Longitude de Londres
        size: 0.1,  // Taille du marqueur
      },
      {
        location: [18.736508999999998,-70.1617435],  // Latitude et Longitude de Londres
        size: 0.1,  // Taille du marqueur
      },
      {
        location: [14.746333499999999,-86.2530975],  // Latitude et Longitude de Londres
        size: 0.1,  // Taille du marqueur
      },
      {
        location: [7.365302,12.343439499999988],  // Latitude et Longitude de Londres
        size: 0.1,  // Taille du marqueur
      },
      {
        location: [7.5468545,-5.547099500000002],  // Latitude et Longitude de Londres
        size: 0.1,  // Taille du marqueur
      },
      {
        location: [7.955012,-1.0318194999999832],  // Latitude et Longitude de Londres
        size: 0.1,  // Taille du marqueur
      }
    ],
    markerColor: [141 / 255, 192 / 255, 77 / 255],
  };


  @ViewChild("scrollHistory")
  scrollHistory!: ElementRef;

  @ViewChild("scrollPresence")
  scrollPresence!: ElementRef;

  itemsNavigation:IItemNavigation[] = Constants.itemNavigationsAbout;
  itemsPaysClients:IPaysClient[] =  Constants.itemsPaysClients;

  constructor(protected responsive: ResponsiveService) { }

  /**
   * @name goToScrollNavigation
   * @memberof AboutUsComponent
   * @param contener nom du container sur lequel on veut scroll
   * @description permet au clique de scroll d'un élément à un autre avec un effet smooth
   */
  setScroll($event: any): void {
    let contenairScroll: ElementRef = $event.scroll == 'history' ? this.scrollHistory : this.scrollPresence;
    contenairScroll.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
