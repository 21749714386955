import { Component } from '@angular/core';
import { Constants } from '../../tools/constants';
import { FormRequestComponent } from '../form-request/form-request.component';

/**
 * @name AskForDemoComponent
 * @description componenent passe-plat pour un form-request avec initialisation de variables néccessaires
 */
@Component({
  selector: 'app-ask-for-demo',
  standalone: true,
  imports: [FormRequestComponent],
  templateUrl: './ask-for-demo.component.html'
})
export class AskForDemoComponent {
  codeTitle: string = 'pageHeader.ask-for-demo';
  type: string = 'demo'
  repository: string = 'replies';
  pathDemo: string = Constants.pathDemo;
}
