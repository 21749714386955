<app-farms-navigation (setScroll)="setScroll($event)"></app-farms-navigation>

<div class="containt-packs" #scrollPacks
    [ngClass]="{'xs-view': responsive.isXsView, 'sm-view': responsive.isSmView, 'md-view': responsive.isMdView}">

    <div class="containt-logo">
        <img src="{{logoFarms}}" alt="icone logo">
    </div>

    <div class="title-master">{{'pageSolutions.title_farms' | translate}}</div>
    <div class="sub-title-master">{{'pagePacks.content' | translate}}</div>

    <div class="containt-imgs-packs" [ngClass]="{'xs-view': responsive.isXsView, 'sm-view': responsive.isSmView}">
        <div class="containt-img" *ngFor="let pack of itemsPack; let indexPack = index"
            [ngClass]="{'xs-view': responsive.isXsView}">
            <img [ngClass]="{'active': indexPack == currentIndex,'xs-view': responsive.isXsView, 'sm-view': responsive.isSmView}"
                (click)="onChangePack(indexPack)" src="{{pack.image + currentLocale + '.png'}}" alt="image pack">
        </div>
    </div>

    <div class="containt-avantages"
        [ngClass]="{'xs-view': responsive.isXsView, 'sm-view': responsive.isSmView, 'md-view': responsive.isMdView}">
        <div class="card-avantage" *ngFor="let detail of currentPack.details; let indexAvg = index"
            [ngClass]="{'opacity-avg no-aviable': (indexAvg + 1) > currentPack.packsValid, 'xs-view': responsive.isXsView, 'sm-view': responsive.isSmView, 'md-view': responsive.isMdView}">
            <div [ngClass]="{'no-aviable': (indexAvg + 1) > currentPack.packsValid}" class="title-avantage">
                {{detail.title | translate}}</div>
            <ul>
                <li *ngFor="let avg of detail.avantages">
                    <img src="{{avg.icon}}" class="icon-avg" /> {{avg.code | translate}}
                </li>
            </ul>
            <img src="{{urlShadow}}" alt="image ombre" class="shadow-avg">
        </div>
    </div>
</div>
<div #scrollContact></div>
<app-form-request [codeTitle]="codeTitle" [repository]="repository" [type]="type"
    [style.background-image]="pathContact"></app-form-request>