<div class="containt-footer">
    <div id="media" class="detail-footer">
        <div class="title-detail link-media'">
            {{ 'footer.farms'| translate}}
        </div>

        <div class="icon-media-item">
            <a href="https://www.linkedin.com/company/farmsdigital?trk=public_post_follow-view-profile" target="_blank">
                <img class="icon-title" alt="icone footer" src="/assets/images/icon_linkdin.png" />
            </a>
            <a href="https://www.youtube.com/@FARMS-DIGITAL" target="_blank">
                <img class="icon-title" alt="icone footer" src="/assets/images/icon_youtube.png" />
            </a>
            <a href="https://www.compagniefruitiere.fr/" target="_blank">
                <img class="icon-title" alt="icone footer" src="/assets/images/icon_cf.png" />
            </a>
        </div>
    </div>

    <div id="contact" class="detail-footer">

        <div class="title-detail">
            <a class="link-mail" href="mailto:contact@farms-digital.com"><img class="icon-title pointer"
                    alt="icone footer" src="/assets/images/icon_mail.png" /><span
                    class="sub-title">{{'contact@farms-digital.com'}}</span></a>
        </div>
    </div>

    <div id="newsletter" class="detail-footer" (click)="openModalNewsletter()">
        <img class="icon-title pointer" alt="icone footer" src="/assets/images/icon_newsletter.png" />

        <div class="title-detail pointer">
            {{ 'footer.newsletter' | translate}}
        </div>
    </div>

    <div class="detail-footer">
        <a href="{{linkLegalNotices}}" class="title-detail link" target="_blank">{{'footer.mentions_legales'|
            translate}}</a>
    </div>

</div>