import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { ResponsiveService } from '../../services/responsive.service';
import { UploadAwsService } from '../../services/upload-aws.service';
import { LanguageComponent } from '../language/language.component';

/**
 * @name FormRequestComponent
 * @description permet la gestion d'un formaulaire de contact ou de démo avec une initalisation
 * d'un FormGroup avec les controls des champs. Au submit les information sont envoyés en JSON
 * sur un bucket S3 grâce au service AWS
 */
@Component({
  selector: 'app-form-request',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, LanguageComponent, FormsModule],
  providers: [AppTranslationService],
  templateUrl: './form-request.component.html',
  styleUrl: './form-request.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class FormRequestComponent {

  @Input() codeTitle!: string;
  @Input() repository!: string;
  @Input() type!: string;

  formRequest!: FormGroup;
  currentLanguage!: string;
  acceptData: boolean = false;
  wizzClass:string = "";

  constructor(protected responsive: ResponsiveService,
    protected alertService: AlertService,
    protected translation: AppTranslationService,
    protected formBuilder: FormBuilder,
    private readonly serviceAws: UploadAwsService,
    private translate: TranslateService
  ) { }

  /**
   * @name ngOnInit
   * @memberof FormRequestComponent
   * @description initialisation
   */
  ngOnInit() {
    this.currentLanguage = this.translate.currentLang;
    this.buildForm();
  }

  /**
   * @name buildForm
   * @memberof FormRequestComponent
   * @description permet l'initisation du du formgroup avec les formControls
   */
  buildForm(): void {
    const validationString: Validators = ['', [Validators.required, Validators.minLength(3)]];
    this.formRequest = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email]],
        company: validationString,
        firstName: validationString,
        lastName: validationString,
        message: validationString,
        language: this.currentLanguage,
        date: moment().format('DD/MM/YYYY H:mm:ss'),
        type: this.type
      }
    )
  }

  /**
   * @name onSubmit
   * @memberof FormRequestComponent
   * @description permet à la validation du formulaire de faire appel au service AWS pour envoyer le formulaire sur le bucket S3
   */
  onSubmit(): void {
    if (!this.acceptData) {
      this.onStartAnimation('wizz', 300, true);
      return;
    }

    if (this.formRequest.valid) {
      this.serviceAws.uploadFile(JSON.stringify(this.formRequest.value), this.repository); //normal que cela soit en commentaire pour le moment
      this.alertService.showMessage(this.translation.getTranslation('commons.message'), this.translation.getTranslation('commons.email_send'), MessageSeverity.success);
      this.buildForm();
      this.acceptData = false;
    } else {
      this.alertService.showMessage(this.translation.getTranslation('commons.message'), this.translation.getTranslation('commons.field_required'), MessageSeverity.info);
    }
  }

   /**
   * @name onStartAnimation
   * @memberof FormRequestComponent
   * @description permet de lancer une animation sur un contenair
   */
   onStartAnimation(animation: string, timer: number, restart?: boolean): void {
    this.wizzClass = animation;
    if (restart) {
      setTimeout(() => {
        this.wizzClass = '';
      }, timer);
    }
  }
}