<app-header (changeValueMenuOpen)="changeValueMenuOpen($event)" [isOpenMenu]="isOpenMenu"></app-header>
<main>
    <div (scroll)="onScroll($event)" class="master-container">
        <router-outlet></router-outlet>
    </div>

</main>
<app-footer (openNewsletter)="openNewsletter($event)"></app-footer>

<div class="back-drop" *ngIf="showNewsletter"></div>
<div class="popup-email" *ngIf="showNewsletter"
    [ngClass]="{'xs-view': response.isXsView, 'md-view': response.isMdView, 'sm-view': response.isSmView}">
    <img class="icon-close-popup" (click)="closeModal()" alt="icone close" src="/assets/images/close.png" />

    <div class="title-popup">newsletters</div>
    <div class="content-newsletter">{{ 'newsletter.inscription' | translate}}</div>
    <div class="d-flex content-forms-news"
    [ngClass]="{'xs-view': response.isXsView}"> <input type="email" id="email-newsletter" name="email-newsletter"
            class="form-control email-content" [(ngModel)]="emailModel" />
        <div (click)="onSubmit()" class="btn-newsletter">{{ 'footer.souscribe' | translate}}</div>
    </div>
    <div class="containt-legal">
        <div class="containt-data-legals" [class]="wizzClass">
            <input type="checkbox" [(ngModel)]="acceptData" [ngModelOptions]="{standalone: true}" />
            <div class="mentions-legals">{{ 'newsletter.mentions' | translate}}
            </div>
        </div>
    </div>
</div>