<app-farms-navigation (setScroll)="setScroll($event)"></app-farms-navigation>
<div class="containt-application" #scrollApplication
    [ngClass]="{'xs-view': responsive.isXsView, 'sm-view': responsive.isSmView, 'md-view': responsive.isMdView}">
    <div class="containt-main-application">
        <div class="containt-logo">
            <img src="{{logoFarms}}" alt="icone logo">
        </div>

        <div class="title-master">{{'pageSolutions.title_farms' | translate}}</div>
        <div class="sub-title-master">{{'pageFarms.sub_title' | translate}}</div>
        <div class="content-master" [innerHtml]="'pageFarms.main_content' | translate"></div>

        <img class="constellation-farms" src="{{urlConstellation}}" alt="constellation farms">
    </div>

    <div class="containt-module-app">
        <app-card-application *ngFor="let app of itemsCardApplication" [app]="app">
        </app-card-application>
    </div>
</div>
<div #scrollContact></div>
<app-form-request [codeTitle]="codeTitle" [repository]="repository" [type]="type"
    [style.background-image]="pathContact"></app-form-request>