import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ResponsiveService } from '../../services/responsive.service';
import { Constants } from '../../tools/constants';

@Component({
  selector: 'app-farms-navigation',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterLink],
  templateUrl: './farms-navigation.component.html',
  styleUrl: './farms-navigation.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class FarmsNavigationComponent {

  shadow: string = Constants.shadow;
  @Output() setScroll = new EventEmitter<{ scroll: string }>();

  constructor(protected responsive: ResponsiveService) { }

  /**
   * @name goToScrollNavigation
   * @memberof FarmsNavigationComponent
   * @param contener nom du container sur lequel on veut scroll
   * @description permet au clique de scroll d'un élément à un autre avec un effet smooth
   */
  goToScrollNavigation(contener: string): void {
    this.setScroll.emit({ scroll: contener })
  }
}
