import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IIndicator } from '../../models/indicator.model';
import { ResponsiveService } from '../../services/responsive.service';

/**
 * @name IndicatorOfferingComponent
 * @description permet l'affichage des indicateur dans un card avec une icone et du code translate
 * avec un style sympa deux autres cards en z-index inférieur et du rotate
 */
@Component({
  selector: 'app-indicator-offering',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './indicator-offering.component.html',
  styleUrl: './indicator-offering.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class IndicatorOfferingComponent {

  @Input() indicator!: IIndicator;

  constructor(protected responsive: ResponsiveService) { }
}
