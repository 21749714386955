<app-farms-navigation [tabNavigation]="itemsNavigation" (setScroll)="setScroll($event)"></app-farms-navigation>
<div class="containt-about" #scrollHistory>
    <!-- CONTAINT HISTORY -->
    <div class="containt-history"
        [ngClass]="{'xs-view': responsive.isXsView, 'sm-view': responsive.isSmView, 'md-view': responsive.isMdView}">
        <div class="title-master" [ngClass]="{'xs-view': responsive.isXsView,}">{{'pageAboutUs.title_history' |
            translate}}</div>

        <div class="containt-timeline">
            <div class="timeline"></div>
            <app-item-history *ngFor="let itemH of itemsHistory" [itemHistory]="itemH"></app-item-history>
        </div>

    </div>
    <!-- CONTAINT PRESENCE -->
    <div class="containt-presence" #scrollPresence
        [ngClass]="{'xs-view': responsive.isXsView, 'sm-view': responsive.isSmView, 'md-view': responsive.isMdView}">
        <div class="title-master" [ngClass]="{'xs-view': responsive.isXsView}">{{'pageAboutUs.title_presence' |
            translate}}</div>

        <div class="containt-master-pays"
            [ngClass]="{'xs-view': responsive.isXsView || responsive.isSmView || responsive.isMdView}">
            <om-globe [globeSize]="500" [globeOptions]="globeOptions"
                [ngClass]="{'xs-view': responsive.isXsView || responsive.isSmView || responsive.isMdView  }"></om-globe>
            <div class="containt-pays" [ngClass]="{'xs-view': responsive.isXsView || responsive.isSmView || responsive.isMdView}">

                <div *ngFor="let pays of itemsPaysClients" class="card-pays">
                    <div class="title-pays">
                        <img class="img-pays" src="{{pays.img}}" alt="icone logo">
                        {{ pays.code | translate}}
                    </div>
                    <div class="liste-pays">
                        <div *ngFor="let client of pays.clients">{{client}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
