import { Component, OnInit } from '@angular/core';
import { LayoutNavigationComponent } from '../layout-navigation/layout-navigation.component';

/**
 * @name HomeComponent
 * @description permet la gestion de l'écran Accueil juste en faisant passe plat avec
 * l'injection du LayoutNavigationComponent
 */
@Component({
  selector: 'app-home',
  standalone: true,
  imports: [LayoutNavigationComponent],
  templateUrl: './home.component.html'
})
export class HomeComponent {

}
