import { Injectable } from '@angular/core';
import S3 from 'aws-sdk/clients/s3';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class UploadAwsService {

  uploadFile(file: any, folder: string) {
    const contentType = 'application/json';
    const bucket = new S3(
      {
        accessKeyId: 'AKIATZTDWFO6JNLKGQSW',
        secretAccessKey: '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',
        region: 'eu-west-3'
      }
    );

    let date = moment().format('DD_MM_YYYY_H_mm_ss');

    const params = {
      Bucket: `www-prod-cf-farms-forms/${folder}`,
      Key: `contact_farms_solutions_${date}`,
      Body: file,
      ContentType: contentType
    };
    bucket.upload(params, function (err: any, data: any) {
      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }
      console.log('Successfully uploaded file.', data);
      return true;
    });
  }
}