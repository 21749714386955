<div class="containt-about">
    <!-- BLOC BTN -->
    <div class="containt-about-btn">
        <div class="btn-about" (click)="goToScrollNavigation('history')">{{'pageAboutUs.btn_history' | translate}}</div>
        <div class="btn-about" (click)="goToScrollNavigation('presence')">{{'pageAboutUs.btn_world-presence' |
            translate}}</div>
        <img src="{{shadow}}" alt="ombre portée">
    </div>

    <!-- CONTAINT HISTORY -->
    <div class="containt-history" #scrollHistory
        [ngClass]="{'xs-view': responsive.isXsView, 'sm-view': responsive.isSmView, 'md-view': responsive.isMdView}">
        <div class="title-master" [ngClass]="{'xs-view': responsive.isXsView,}">{{'pageAboutUs.title_history' |
            translate}}</div>
        <app-item-history *ngFor="let itemH of itemsHistory" [itemHistory]="itemH"></app-item-history>

        <div class="final-history">
            <img src="{{finalHistory}}" alt="pin de date">
            <div class="text-final-history" innerHtml="{{'pageAboutUs.content_2024' | translate}}"></div>
        </div>
    </div>
    <!-- CONTAINT PRESENCE -->
    <div class="containt-presence" #scrollPresence
        [ngClass]="{'xs-view': responsive.isXsView, 'sm-view': responsive.isSmView, 'md-view': responsive.isMdView}">
        <div class="title-master" [ngClass]="{'xs-view': responsive.isXsView,}">{{'pageAboutUs.title_presence' |
            translate}}</div>
        <img src="{{pathPresence}}" alt="image presence mondiale">
        <img class="icon-scroll-up" src="{{scrollUp}}" alt="icone de scroll vers le haut"
            (click)="goToScrollNavigation('history')">
    </div>
</div>