<div class="title-request"
    [ngClass]="{'md-view': responsive.isMdView, 'sm-view': responsive.isSmView, 'xs-view': responsive.isXsView}">
    {{ codeTitle | translate }}</div>
<div class="containt-form"
    [ngClass]="{'md-view': responsive.isMdView, 'sm-view': responsive.isSmView, 'xs-view': responsive.isXsView}">

    <form [formGroup]="formRequest" (ngSubmit)="onSubmit()">

        <div class="row-fields" [ngClass]="{'xs-view': responsive.isXsView}">
            <div class="containt-field" [ngClass]="{'sm-view': responsive.isSmView || responsive.isXsView}">
                <!-- CHAMP EMAIL -->
                <label class="lable-contact" for="email">{{'contact.email' | translate }} :</label>
                <input class="field-contact form-control" id="email" formControlName="email"
                    [ngClass]="{'sm-view': responsive.isSmView || responsive.isXsView}" />
                <div class="containt-small"
                    *ngIf="formRequest.get('email')?.invalid && formRequest.get('email')?.touched">
                    <small *ngIf="formRequest.get('email')?.errors?.['required']">{{'contact.email' | translate}}
                        {{'commons.required' | translate}}.</small>
                    <small *ngIf="formRequest.get('email')?.errors?.['email']">{{'contact.email' | translate}}
                        {{'commons.invalid' | translate}}.</small>
                </div>
            </div>
            <div class="containt-field" *ngIf="!responsive.isXsView"></div>
        </div>

        <div class="row-fields" [ngClass]="{'xs-view': responsive.isXsView}">
            <div class="containt-field" [ngClass]="{'sm-view': responsive.isSmView || responsive.isXsView}">
                <!-- CHAMP SOCIETE -->
                <label class="lable-contact" for="company">{{'contact.societe' | translate}} :</label>
                <input class="field-contact form-control" id="company" formControlName="company"
                    [ngClass]="{'sm-view': responsive.isSmView || responsive.isXsView}" />

                <div class="containt-small"
                    *ngIf="formRequest.get('company')?.invalid && formRequest.get('company')?.touched">
                    <small *ngIf="formRequest.get('company')?.errors?.['required']">{{'contact.societe' | translate}}
                        {{'commons.required' | translate}}.</small>
                    <small *ngIf="formRequest.get('company')?.errors?.['minlength']">{{'contact.societe' | translate}}
                        {{'commons.min_3' | translate}}.</small>
                </div>
            </div>

            <div class="containt-field" [ngClass]="{'sm-view': responsive.isSmView || responsive.isXsView}">
                <label class="lable-contact" for="firstName">{{'commons.language' | translate}}:</label>
                <app-language [dropdown]="false"></app-language>
            </div>
        </div>

        <div class="row-fields" [ngClass]="{'xs-view': responsive.isXsView}">
            <div class="containt-field" [ngClass]="{'sm-view': responsive.isSmView || responsive.isXsView}">
                <!-- CHAMP PRENOM -->
                <label class="lable-contact" for="firstName">{{'contact.prenom' | translate}} :</label>
                <input class="field-contact form-control" id="firstName" formControlName="firstName"
                    [ngClass]="{'sm-view': responsive.isSmView || responsive.isXsView}" />

                <div class="containt-small"
                    *ngIf="formRequest.get('firstName')?.invalid && formRequest.get('firstName')?.touched">
                    <small *ngIf="formRequest.get('firstName')?.errors?.['required']">{{'contact.prenom' | translate}}
                        {{'commons.required' | translate}}.</small>
                    <small *ngIf="formRequest.get('firstName')?.errors?.['minlength']">{{'contact.prenom' | translate}}
                        {{'commons.min_3' | translate}}.</small>
                </div>
            </div>

            <div class="containt-field" [ngClass]="{'sm-view': responsive.isSmView || responsive.isXsView}">
                <!-- CHAMP NOM DE FAMILLE -->
                <label class="lable-contact" for="lastName">{{'contact.nom' | translate}} :</label>
                <input class="field-contact form-control" id="lastName" formControlName="lastName"
                    [ngClass]="{'sm-view': responsive.isSmView || responsive.isXsView}" />

                <div class="containt-small"
                    *ngIf="formRequest.get('lastName')?.invalid && formRequest.get('lastName')?.touched">
                    <small *ngIf="formRequest.get('lastName')?.errors?.['required']">{{'contact.nom' | translate}}
                        {{'commons.required' | translate}}.</small>
                    <small *ngIf="formRequest.get('lastName')?.errors?.['minlength']">{{'contact.nom' | translate}}
                        {{'commons.min_3' | translate}}.</small>
                </div>
            </div>
        </div>

        <div class="row-fields" [ngClass]="{'xs-view': responsive.isXsView}">
            <div class="containt-field" [ngClass]="{'sm-view': responsive.isSmView || responsive.isXsView}">
                <!-- CHAMP MESSAGE -->
                <label class="lable-contact" for="message">{{'contact.request' | translate}} :</label>
                <textarea class="field-contact form-control" id="message" formControlName="message"
                    [ngClass]="{'sm-view': responsive.isSmView || responsive.isXsView}"></textarea>

                <div class="containt-small"
                    *ngIf="formRequest.get('message')?.invalid && formRequest.get('message')?.touched">
                    <small *ngIf="formRequest.get('message')?.errors?.['required']">{{'contact.request' | translate}}
                        {{'commons.required' | translate}}.</small>
                    <small *ngIf="formRequest.get('message')?.errors?.['minlength']">{{'contact.request' | translate}}
                        {{'commons.min_3' | translate}}.</small>
                </div>
            </div>
        </div>


        <button class="btn-submit-form" type="submit">{{'contact.submit' | translate}}</button>
    </form>
    <div class="containt-legal">
        <div class="containt-data-legals" [class]="wizzClass">
            <input type="checkbox" [(ngModel)]="acceptData" [ngModelOptions]="{standalone: true}" />
            <div class="mentions-legals">{{ 'contact.mentions' | translate}}
            </div>
        </div>
    </div>
</div>