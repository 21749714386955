import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { IItemCardApplication } from '../../models/item-card-application.model';
import { ResponsiveService } from '../../services/responsive.service';

/**
 * @name CardApplicationComponent
 * @description permet l'affichage des cards applications avec la gestion des type de card avec title, image avec
 * gestion de langue
 */
@Component({
  selector: 'app-card-application',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './card-application.component.html',
  styleUrl: './card-application.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class CardApplicationComponent {

  @Input() app!: IItemCardApplication;
  currentLocale!: string;

  constructor(private translate: TranslateService, public response: ResponsiveService) {
    this.currentLocale = this.translate.currentLang;
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLocale = event.lang;
    });
  }
}
