<div class="master-offering">

    <!-- BLOC OFFERING -->
    <div class="containt-offering"
        [ngClass]="{'xs-view': responsive.isXsView, 'sm-view': responsive.isSmView, 'md-view': responsive.isMdView}">
        <div class="title-master">{{ 'pageOffering.title_offering' | translate }}</div>

        <!-- BLOC INDICATEURS -->
        <div class="containt-indicators" [ngClass]="{'xs-view': responsive.isXsView}">
            <app-indicator-offering *ngFor="let indic of indicators" [indicator]="indic"
                [ngClass]="{'xs-view': responsive.isXsView}"></app-indicator-offering>
        </div>
        <!-- <div class="content-offering">{{ 'pageOffering.content_offering' | translate }}</div> -->

        <div class="content-bloc-offering" [ngClass]="{'md-view': responsive.isSmView || responsive.isXsView}" [innerHTML]="'pageOffering.content_bloc_offering' | translate"></div>
    </div>

    <!-- BLOC BENEFITS -->
    <div class="containt-benefits"
        [ngClass]="{'xs-view': responsive.isXsView, 'sm-view': responsive.isSmView, 'md-view': responsive.isMdView}">
        <div class="title-master mb-2">{{ 'pageOffering.title_benefits' | translate }}</div>
        <div class="text-benefits text-center">{{'pageOffering.content_benefits' | translate}}</div>
        <div class="content-benefits mb-5">
            <div class="row-polygon" [ngClass]="{'xs-view': responsive.isXsView || responsive.isSmView}">
                <app-polygon-card *ngFor="let item of itemsPolygons" [data]="item" class="{{currentLanguage}}"></app-polygon-card>
            </div>

            <!-- <div class="row-polygon" [ngClass]="{'xs-view': responsive.isXsView || responsive.isSmView}">
                <app-polygon-card *ngFor="let item of itemsPolygons[1]" [data]="item"
                    [ngClass]="{'order-3': item.central && responsive.isXsView}"></app-polygon-card>
            </div>

            <div class="row-polygon" [ngClass]="{'xs-view': responsive.isXsView || responsive.isSmView}">
                <app-polygon-card *ngFor="let item of itemsPolygons[2]" [data]="item"></app-polygon-card>
            </div> -->

        </div>
    </div>
</div>