<!-- <div class="containt-items-navigation" [ngStyle]="getImageStyle()" -->
    <div class="containt-items-navigation"
    [ngClass]="{'row-view': responsive.isMdView || responsive.isWebView, 'col-view': responsive.isSmView || responsive.isXsView}">
    <app-card-navigation *ngFor="let itemCard of itemsNavigation" (click)="onChangeCurrentCard(itemCard)"
        [itemCardNavigation]="itemCard"
        [ngClass]="{'full-width': itemCard.open, 'close-animation':!itemCard.open}"></app-card-navigation>
</div>

<div class="video-background">
    <video id="background-video" [muted]="'muted'" loop autoplay src="assets/images/plantation.mp4" type="video/mp4"  playsinline>
    </video>
  </div>