import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IMenu } from '../../models/menu.model';
import { ResponsiveService } from '../../services/responsive.service';
import { Constants } from '../../tools/constants';
import { LanguageComponent } from '../language/language.component';
import { MenuComponent } from '../menu/menu.component';
import { RouterLink } from '@angular/router';

/**
 * @name HeaderComponent
 * @description permet la gestion du header du site. Injection de plusieur composant angular
 * comme le MenuComponent et Language qui permettent la gestion de la navigation et du language
 */
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, TranslateModule, MenuComponent, LanguageComponent, RouterLink],
  providers: [],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {

  urlLogo: string = Constants.logo;
  menus: IMenu[] = Constants.menus;
  urlShadow: string = Constants.shadow;

  @Input() isOpenMenu: boolean = false;
  @Output() changeValueMenuOpen = new EventEmitter<boolean>()

  constructor(public responsive: ResponsiveService) { }


  /**
   * @name openMenu
   * @memberof HeaderComponent
   * @description permet d'ouvrir ou fermer le menu
   */
  openOrCloseMenu(): void {
    this.isOpenMenu = !this.isOpenMenu;
    this.changeValueMenuOpen.emit(this.isOpenMenu);
  }

  /**
   * @name closeModal
   * @memberof HeaderComponent
   * @param $event boolean de fermeture de popup
   * @description permet à la reception de l'$event de changer la valeur de isOpenMenu
   */
  closeModal($event: boolean): void {
    this.isOpenMenu = $event;
  }
}
