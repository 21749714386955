import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IItemNavigation } from '../../models/item-navigation.model';
import { ResponsiveService } from '../../services/responsive.service';
import { Constants } from '../../tools/constants';

/**
 * @name CardNavigationComponent
 * @description Grace à l'input itemCardNavigation qui contient les informations d'un card lié au composant parent
 * LayoutNavigationComponent permet de gérer les informations à afficher ainsi que le responsive grave au ResponsiveSerce
 * Dans l'affichage d'une card :
 * titre, sous-titre, content de type text et un bouton qui sert à la navigation 
 */
@Component({
  selector: 'app-card-navigation',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterLink],
  templateUrl: './card-navigation.component.html',
  styleUrl: './card-navigation.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class CardNavigationComponent {

  arrowGreen: string = Constants.arrowGreen;
  showArrow: boolean = false;

  @Input() itemCardNavigation!: IItemNavigation;

  constructor(protected responsive: ResponsiveService) { }
}
