<div class="containt-languages">
  <div class="containt-icon" *ngFor="let language of languages"
    [ngClass]="{'active': currentLanguage == language.code,'d-none':(responsive.isXsView || responsive.isSmView) && dropdown}">
    <img src="{{language.icon}}" (click)="onChangeLanguage(language.code)" alt="image pays">
  </div>

  <div class="dropdown" *ngIf="(responsive.isXsView || responsive.isSmView) && dropdown">
    <button class="btn btn-secondary dropdown-toggle" type="button" id="dpMenuLanguage" data-bs-toggle="dropdown"
      aria-expanded="false">
      <img src="assets/images/locale_{{currentLanguage}}.png" alt="image pays">
    </button>

    <ul class="dropdown-menu" aria-labelledby="dpMenuLanguage">
      <ng-container *ngFor="let language of languages">
        <li *ngIf="currentLanguage != language.code">
          <a class="dropdown-item" href="javascript:;" (click)="onChangeLanguage(language.code)"><img
              src="{{language.icon}}" (click)="onChangeLanguage(language.code)" alt="image pays">
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</div>