import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { cloneDeep, first } from 'lodash';
import { IItemNavigation } from '../../models/item-navigation.model';
import { ResponsiveService } from '../../services/responsive.service';
import { Constants } from '../../tools/constants';
import { CardNavigationComponent } from '../card-navigation/card-navigation.component';


/**
 * @name LayoutNavigationComponent
 * @description permet la gestion du layout navigation avec des composants CardNavigation
 * Actuellement une navigation à 3 card avec une gestion d'affichage
 */
@Component({
  selector: 'app-layout-navigation',
  standalone: true,
  imports: [CommonModule, CardNavigationComponent],
  templateUrl: './layout-navigation.component.html',
  styleUrl: './layout-navigation.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class LayoutNavigationComponent implements OnInit {

  itemsNavigation: IItemNavigation[] = cloneDeep(Constants.itemNavigations);
  currentItemNavigation!: IItemNavigation;

  constructor(protected responsive: ResponsiveService) { }

  /**
   * @name ngOnInit
   * @memberof LayoutNavigationComponent
   * @description initialisation
   */
  ngOnInit() {
    this.currentItemNavigation = first(this.itemsNavigation)!;
  }

  /**
   * @name getImageStyle
   * @memberof LayoutNavigationComponent
   * @description permet de retourner la config style avec l'url du currentItemNavigation
   */
  getImageStyle() {
    return {
      backgroundImage: `url(${this.currentItemNavigation.image})`,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }
  }

  /**
   * @name onChangeCurrentCard
   * @memberof LayoutNavigationComponent
   * @param card currentItemNavigation
   * @description permet de changer le currentItemNavigation et de modifier la propriété open 
   */
  onChangeCurrentCard(card: IItemNavigation) {
    if (card == this.currentItemNavigation) {
      return;
    }

    this.currentItemNavigation.open = false;
    this.currentItemNavigation = card;
    setTimeout(() => {
      this.currentItemNavigation.open = true;
    }, 100);
  }
}
