<div class="containt-card-app">
    <div class="containt-master-app" [ngClass]="{'xs-view': response.isXsView}">
        <div *ngIf="app.typeCard == 'amelioration' || app.duo" class="title-app"
            [ngClass]="{'xs-view': response.isXsView}">{{app.title | translate}}</div>
        <div *ngIf="!app.duo" class="master-content-app"
            [ngClass]="{'flex-row': app.typeCard != 'amelioration', 'flex-column':app.typeCard == 'amelioration'}">

            <div class="containt-content" *ngIf="!app.duo"
                [ngClass]="{'order-2': app.imgFirst, 'order-1': !app.imgFirst}">
                <div [ngClass]="{'xs-view': response.isXsView}" *ngIf="app.typeCard != 'amelioration'"
                    class="title-app">{{app.title | translate}}</div>
                <div class="content-app" [innerHTML]="app.content | translate"></div>

                <div *ngIf="app.subImg != null" class="containt-sub-img-app">
                    <img [ngClass]="{'xs-view': response.isXsView}" src="{{app.subImg + currentLocale + '.png'}}"
                        alt="image application">
                </div>
            </div>
            <div *ngIf="!app.duo" class="containt-img-app">
                <img [ngClass]="{'xs-view': response.isXsView}" class="{{app.typeCard}}" src="{{app.imgs[0]}}"
                    alt="image application">
            </div>
        </div>

        <div *ngIf="app.duo" class="containt-imgs-duo">
            <img [ngClass]="{'order-1': app.imgFirst, 'order-2': !app.imgFirst, 'xs-view': response.isXsView, 'sm-view': response.isSmView}"
                src="{{app.imgs[0] + currentLocale + '.png'}}" alt="image applicatio">
        </div>

        <div *ngIf="app.duo" class="containt-duo-content">
            <div class="containt-content">{{app.content | translate}}</div>
            <div *ngIf="app.subTitle != null" class="duo-sub-title">{{app.subTitle | translate}}</div>
            <div *ngIf="app.subContent != null" class="duo-sub-content" [innerHtml]="app.subContent | translate"></div>
        </div>
    </div>
</div>