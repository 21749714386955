import { ICardProduct } from '../models/card-product.model';
import { IDetailsFooter } from '../models/details-footer.model';
import { IIndicator } from '../models/indicator.model';
import { IItemCardApplication } from '../models/item-card-application.model';
import { IItemHistory } from '../models/item-history.model';
import { IItemNavigation } from "../models/item-navigation.model";
import { IDetailsPack, IItemPack } from '../models/item-pack.model';
import { IItemPolygon } from '../models/item-polygon.model';
import { ILanguage } from "../models/language.model";
import { IMenu } from "../models/menu.model";

export class Constants {

    public static readonly logo: string = './assets/images/logo-farms-digital-headers.png';
    public static readonly shadow: string = './assets/images/shadow.png';
    public static readonly pathIconScroll: string = './assets/images/icon_contact.png';
    public static readonly pathLegalNotices: string = '/assets/files/FARMS-DIGITAL-Legal-Notices-2025.pdf';
    public static readonly arrowGreen: string = '/assets/images/arrow_green.png';
    public static readonly pathDemo: string = "url('/assets/images/background_demo.png')";
    public static readonly pathContact: string = "url('/assets/images/nav-farms-team.jpg')";
    public static readonly pathPresence: string = "/assets/images/presence2.jpg";
    public static readonly finalHistory: string = "/assets/images/history_2024.png";
    public static readonly scrollUp: string = "/assets/images/scroll_up.png";


    public static readonly languages: ILanguage[] = [
        { code: 'fr', icon: '/assets/images/locale_fr.png' },
        { code: 'en', icon: '/assets/images/locale_en.png' },
        { code: 'es', icon: '/assets/images/locale_es.png' }
    ];

    public static readonly menus: IMenu[] = [
        { code: 'pageHeader.home', route: '/home', icon: '/assets/images/home-icon.png' },
        { code: 'pageHeader.solutions', route: '/solutions', icon: '/assets/images/solutions.png' },
        { code: 'pageHeader.offering', route: '/offering', icon: '/assets/images/offering.png' },
        { code: 'pageHeader.about-us', route: '/about-us', icon: '/assets/images/about-us.png' },
        { code: 'pageHeader.contact', route: '/contact', icon: '/assets/images/contact.png' }
    ];

    public static readonly itemNavigations: IItemNavigation[] = [
        {
            title: 'pageHome.farms-solution-title',
            titleDetail: 'pageHome.farms-solutions-title-details',
            content: 'pageHome.farms-solutions-content',
            link: '/about-us',
            image: '/assets/images/plantation360.jpg',
            open: true
        }
    ];

    public static readonly detailsFooters: IDetailsFooter[] = [
        {
            title: 'footer.farms',
            type: 'icon-media',
            icons: [
                { path: '/assets/images/icon_linkdin.png', link: 'https://www.linkedin.com/company/farmsdigital?trk=public_post_follow-view-profile' },
                { path: '/assets/images/icon_youtube.png', link: 'https://www.youtube.com/@FARMS-DIGITAL' },
                { path: '/assets/images/icon_cf.png', link: 'https://www.compagniefruitiere.fr/' }
            ]
        },
        {
            title: 'footer.contact',
            iconTitle: '/assets/images/icon_mail.png',
            type: 'contact',
            content: 'contact@farms-digital.com'
        },
        {
            title: 'footer.newsletter',
            iconTitle: '/assets/images/icon_newsletter.png',
            type: 'email',
        },
        {
            title: 'footer.mentions_legales',
            type: 'link'
        }
    ];

    public static readonly detailsHistory: IItemHistory[] = [
        { date:'2016', title:'pageAboutUs.title_2016', content: 'pageAboutUs.content_2016', image: '/assets/images/history_2016.png', noContent: false, visualFirst: true },
        { date:'2018', title:'pageAboutUs.title_2018',content: 'pageAboutUs.content_2018', image: '/assets/images/history_2018.png', noContent: false, visualFirst: false },
        { date:'2018',endDate: "2020", title:'pageAboutUs.title_2020',content: 'pageAboutUs.content_2020', image: '/assets/images/history_2020.png', noContent: false, visualFirst: true },
        { date:'2021', title:'pageAboutUs.title_2021',content: 'pageAboutUs.content_2021', image: '/assets/images/history_2021.png', noContent: false, visualFirst: false },
        { date:'2022', title:'pageAboutUs.title_2022',content: 'pageAboutUs.content_2022', image: '/assets/images/history_2022.png', noContent: false, visualFirst: true },
        { date:'2022', title:'pageAboutUs.title_2022_2',content: 'pageAboutUs.content_2022_2', image: '/assets/images/history_2022_2.png', noContent: false, visualFirst: false },
        { date:'2023', title:'pageAboutUs.title_2023',content: 'pageAboutUs.content_2023', image: '/assets/images/history_2023.png', noContent: false, visualFirst: true },
        { date:'2024', title:'pageAboutUs.title_2024',contentImg: '/assets/images/history_2024.png', image: '/assets/images/history_2024_2.png', noContent: false, visualFirst: false },
        { date:'2025', title:'pageAboutUs.title_2025',content: 'pageAboutUs.content_2025', image: '/assets/images/history_2025.png', noContent: false, visualFirst: true }
    ];

    public static readonly indicators: IIndicator[] = [
        { code: 'pageOffering.controle', icon: '/assets/images/controle.png', prefixeClass: "controle" },
        { code: 'pageOffering.decision', icon: '/assets/images/decision.png', prefixeClass: "decision" },
        { code: 'pageOffering.amelioration', icon: '/assets/images/amelioration.png', prefixeClass: "amelioration" },
        { code: 'pageOffering.reduction', icon: '/assets/images/reduction.png', prefixeClass: "reduction" }
    ];

    public static readonly itemsPolygons: IItemPolygon[] = [
        { code: 'pageOffering.polygone_1', icon: '/assets/images/controle_1.png' },
        { code: 'pageOffering.polygone_2', icon: '/assets/images/qualite_2.png'  },
        { code: 'pageOffering.polygone_3', icon: '/assets/images/quantite_3.png'  },
        { code: 'pageOffering.polygone_4', icon: '/assets/images/decision_4.png'  },
        { code: 'pageOffering.polygone_5', icon: '/assets/images/soin_5.png'  },
        { code: 'pageOffering.polygone_6', icon: '/assets/images/cartons_6.png'  },
        { code: 'pageOffering.polygone_7', icon: '/assets/images/reduction_7.png'  },
        { code: 'pageOffering.polygone_8', icon: '/assets/images/precision_8.png'  },
        { code: 'pageOffering.polygone_9', icon: '/assets/images/cout_9.png'  },
        { code: 'pageOffering.polygone_10', icon: '/assets/images/temps_10.png'  },
        { code: 'pageOffering.polygone_11', icon: '/assets/images/connaissance.png' }
    ];

    public static readonly itemsCardProduct: ICardProduct[] = [
        { icon: '/assets/images/logo_farms_solution.png', title: 'pageSolutions.title_farms', content: 'pageSolutions.content_farms', path: '/solutions/farms-application', linkContent: 'pageSolutions.btn_details', color: '#8dc04d' },
        { icon: '/assets/images/logo_hiris_solution.png', title: 'pageSolutions.title_hiris', content: 'pageSolutions.content_hiris', path: '/solutions', linkContent: 'pageSolutions.btn_coming_soon', color: '#95cdad' },
    ];

    public static readonly itemsCardApplication: IItemCardApplication[] = [
        {
            title: 'pageApp.sv_title',
            imgs: ['/assets/images/sv_1_'],
            duo: true,
            content: 'pageApp.sv_content',
            subTitle: 'pageApp.sv_sub_title',
            subContent: 'pageApp.sv_sub_content',
            direction: 'column',
            typeCard: "sv"
        },
        {
            title: 'pageApp.prod_title',
            imgs: ['/assets/images/prod_1.png'],
            duo: false,
            content: 'pageApp.prod_content',
            direction: 'row',
            imgFirst: true,
            typeCard: "prod"
        },
        {
            title: 'pageApp.rh_title',
            imgs: ['/assets/images/hr_1.jpg'],
            duo: false,
            content: 'pageApp.rh_content',
            direction: 'row',
            imgFirst: false,
            subImg: '/assets/images/hr_2_',
            typeCard: "rh"
        },
        {
            title: 'pageApp.itk_title',
            imgs: ['/assets/images/itk_1.png'],
            duo: false,
            content: 'pageApp.itk_content',
            direction: 'row',
            imgFirst: true,
            typeCard: "itk"
        },
        {
            title: 'pageApp.amelioration_title',
            imgs: ['/assets/images/amelioration_1.png'],
            duo: false,
            content: 'pageApp.amelioration_content',
            direction: 'columns',
            imgFirst: true,
            typeCard: "amelioration"
        }
    ];

    public static readonly basicPack: IDetailsPack = {
        "title": "pagePacks.prod_title",
        "avantages": [
            { icon: '/assets/images/prod_av_2.png', code: 'pagePacks.prod_av_2' },
            { icon: '/assets/images/prod_av_3.png', code: 'pagePacks.prod_av_basic' },
            { icon: '/assets/images/prod_av_4.png', code: 'pagePacks.prod_av_4' },
            { icon: '/assets/images/prod_av_5.png', code: 'pagePacks.prod_av_5' },
            { icon: '/assets/images/prod_av_6.png', code: 'pagePacks.prod_av_6' },
            { icon: '/assets/images/prod_basic_1.png', code: 'pagePacks.prod_basic_1' },
            { icon: '/assets/images/prod_basic_2.png', code: 'pagePacks.prod_basic_2' }
        ]
    };
    public static readonly prodPack: IDetailsPack = {
        "title": "pagePacks.prod_title",
        "avantages": [
            { icon: '/assets/images/prod_av_1.png', code: 'pagePacks.prod_av_1' },
            { icon: '/assets/images/prod_av_2.png', code: 'pagePacks.prod_av_2' },
            { icon: '/assets/images/prod_av_3.png', code: 'pagePacks.prod_av_3' },
            { icon: '/assets/images/prod_av_4.png', code: 'pagePacks.prod_av_4' },
            { icon: '/assets/images/prod_av_5.png', code: 'pagePacks.prod_av_5' },
            { icon: '/assets/images/prod_av_6.png', code: 'pagePacks.prod_av_6' }
        ]
    };
    public static readonly agroPack: IDetailsPack = {
        "title": "pagePacks.agro_title",
        "avantages": [
            { icon: '/assets/images/agro_av_1.png', code: 'pagePacks.agro_av_1' },
            { icon: '/assets/images/agro_av_2.png', code: 'pagePacks.agro_av_2' },
            { icon: '/assets/images/agro_av_3.png', code: 'pagePacks.agro_av_3' }
        ]
    };
    public static readonly popPack: IDetailsPack = {
        "title": "pagePacks.pop_title",
        "avantages": [
            { icon: '/assets/images/pop_av_1.png', code: 'pagePacks.pop_av_1' },
            { icon: '/assets/images/pop_av_2.png', code: 'pagePacks.pop_av_2' },
            { icon: '/assets/images/pop_av_3.png', code: 'pagePacks.pop_av_3' },
            { icon: '/assets/images/pop_av_4.png', code: 'pagePacks.pop_av_4' }
        ]
    };
    public static readonly itkPack: IDetailsPack = {
        "title": "pagePacks.itk_title",
        "avantages": [
            { icon: '/assets/images/itk_av_1.png', code: 'pagePacks.itk_av_1' },
            { icon: '/assets/images/itk_av_2.png', code: 'pagePacks.itk_av_2' }
        ]
    };
    public static readonly hrPack: IDetailsPack = {
        "title": "pagePacks.hr_title",
        "avantages": [
            { icon: '/assets/images/hr_av_1.png', code: 'pagePacks.hr_av_1' },
            { icon: '/assets/images/hr_av_2.png', code: 'pagePacks.hr_av_2' }
        ]
    }

    public static readonly itemsPack: IItemPack[] = [
        {
            image: '/assets/images/pack_starter_',
            packsValid: 1,
            color:"#8dc04d",
            details: [this.prodPack]
        },
        {
            image: '/assets/images/pack_prod_',
            packsValid: 3,
            color:"#2677c0",
            details: [this.prodPack, this.agroPack, this.popPack]
        },
        {
            image: '/assets/images/pack_all_inclusive_',
            packsValid: 5,
            color:"#fa9105",
            details: [this.prodPack, this.agroPack, this.popPack, this.itkPack, this.hrPack]
        }
    ];

    public static readonly itemNavigationsBarre: IItemNavigation[] = [
        {
            title: 'pageFarms.applications',
            link: '/solutions/farms-application',
            open: true
        },
        {
            title: 'pageFarms.packs_and_services',
            link: '/solutions/farms-packs',
            open: false
        }
    ];

    public static readonly itemNavigationsAbout: IItemNavigation[] = [
        {
            title: 'pageAboutUs.btn_history',
            link: '/about-us',
            open: true,
            scrollCode: 'history'
        },
        {
            title: 'pageAboutUs.btn_world-presence',
            link: '/about-us',
            open: false,
            scrollCode: 'presence'
        }
    ];
}