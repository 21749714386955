<div class="containt-card" (mouseenter)="showArrow = true;" (mouseleave)="showArrow = false;">
    <div class="back-drop"
        [ngClass]="{'back-drop-open': itemCardNavigation.open, 'back-drop-close': !itemCardNavigation.open}"></div>

    <div class="containt-content" *ngIf="!itemCardNavigation.open">
        <img *ngIf="!itemCardNavigation.open && responsive.isWebView" class="arrow-green" src="{{arrowGreen}}"
            alt="croix verte" [ngClass]="{'show-arrow': showArrow, 'd-none': !showArrow}">
        <div class="btn-title">{{itemCardNavigation.title | translate}}</div>
    </div>

    <div class="containt-details animation-content" *ngIf="itemCardNavigation.open"
        [ngClass]="{'xs-view': responsive.isXsView || responsive.isMdView, 'hand-landscape': responsive.isHandsetLandscape}">
        <div [ngClass]="{'xs-view': responsive.isXsView || responsive.isMdView}" class="subtitle">
            {{itemCardNavigation.subTitle | translate}}</div>
        <div [ngClass]="{'xs-view': responsive.isXsView || responsive.isMdView,'hand-landscape': responsive.isHandsetLandscape}"
            class="title-details">
            {{itemCardNavigation.titleDetail | translate}}</div>
        <div [ngClass]="{'xs-view': responsive.isXsView || responsive.isMdView}" class="content-details">
            {{itemCardNavigation.content | translate}}</div>
        <div [routerLink]="itemCardNavigation.link" class="btn-more">{{'pageHome.more_details' | translate}}</div>
    </div>

</div>