import * as i1 from '@angular/common';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { PLATFORM_ID, Component, Inject, ViewChild, Input } from '@angular/core';
import createGlobe from 'cobe';
const _c0 = ["globeCanvas"];
class NgxGlobeComponent {
  set globeOptions(options) {
    this.globeSize = options.width ?? this.globeSize;
    this.setGlobeOptions(options);
    if (this.globeInitialized) {
      this.initGlobe();
    }
  }
  set globeCanvasSize(size) {
    this.globeSize = size;
    this.cobeOptions.width = this.globeSize;
    this.cobeOptions.height = this.globeSize;
    this.style["--globe-size"] = size + "px";
    if (this.globeInitialized) {
      this.initGlobe();
    }
  }
  constructor(platformId) {
    this.platformId = platformId;
    this.rotationSpeed = 0.005;
    this.style = {};
    this.globeSize = 600;
    this.globeInitialized = false;
    this.cobeOptions = {
      devicePixelRatio: 2,
      width: this.globeSize,
      height: this.globeSize,
      phi: 0,
      theta: 0.3,
      dark: 0,
      diffuse: 0.4,
      scale: 1,
      mapSamples: 16000,
      mapBrightness: 1.2,
      baseColor: [1, 1, 1],
      markerColor: [251 / 255, 100 / 255, 21 / 255],
      glowColor: [1, 1, 1],
      offset: [0, 0],
      markers: [{
        location: [14.5995, 120.9842],
        size: 0.03
      }, {
        location: [19.076, 72.8777],
        size: 0.1
      }, {
        location: [23.8103, 90.4125],
        size: 0.05
      }, {
        location: [30.0444, 31.2357],
        size: 0.07
      }, {
        location: [39.9042, 116.4074],
        size: 0.08
      }, {
        location: [-23.5505, -46.6333],
        size: 0.1
      }, {
        location: [19.4326, -99.1332],
        size: 0.1
      }, {
        location: [40.7128, -74.006],
        size: 0.1
      }, {
        location: [34.6937, 135.5022],
        size: 0.05
      }, {
        location: [41.0082, 28.9784],
        size: 0.06
      }]
    };
    this.pointerInteracting = null;
    this.pointerInteractionMovement = 0;
    this.globeRotation = 0;
    this.isAnimating = false;
  }
  ngAfterViewInit() {
    this.initGlobe();
    if (isPlatformBrowser(this.platformId)) {
      this.intersectionObserver = new IntersectionObserver(([entry]) => {
        this.renderContents(entry.isIntersecting);
      });
      this.intersectionObserver.observe(this.globeCanvas.nativeElement);
    }
  }
  ngOnDestroy() {
    this.globe?.destroy();
    window.removeEventListener("resize", () => this.setCanvasSize());
  }
  setCanvasSize() {
    this.globeCanvas.nativeElement.width = this.globeSize;
    this.globeCanvas.nativeElement.height = this.globeSize;
  }
  renderContents(isIntersecting) {
    if (isIntersecting && !this.isAnimating) {
      this.isAnimating = true;
      this.globe?.toggle(true);
    } else if (!isIntersecting && this.isAnimating) {
      this.isAnimating = false;
      this.globe?.toggle(false);
    }
  }
  initGlobe() {
    let phi = this.cobeOptions.phi;
    let cobeOptions = {
      ...this.cobeOptions,
      onRender: state => {
        if (!this.pointerInteracting) {
          phi += this.rotationSpeed;
        }
        state.phi = phi + this.globeRotation;
      }
    };
    this.globe = createGlobe(this.globeCanvas.nativeElement, cobeOptions);
    this.globeInitialized = true;
    window.addEventListener("resize", () => this.setCanvasSize());
    this.globeCanvas.nativeElement.width = this.globeSize;
    this.globeCanvas.nativeElement.height = this.globeSize;
  }
  updatePointerInteraction(value) {
    this.pointerInteracting = value;
    this.globeCanvas.nativeElement.style.cursor = value ? "grabbing" : "grab";
  }
  updateMovement(clientX) {
    if (this.pointerInteracting !== null) {
      const delta = clientX - this.pointerInteracting;
      this.pointerInteractionMovement = delta;
      this.globeRotation = delta / 200;
    }
  }
  setGlobeOptions(options) {
    this.cobeOptions.width = options.width ?? this.cobeOptions.width;
    this.cobeOptions.height = options.height ?? this.cobeOptions.height;
    this.cobeOptions.phi = options.phi ?? this.cobeOptions.phi;
    this.cobeOptions.theta = options.theta ?? this.cobeOptions.theta;
    this.cobeOptions.mapSamples = options.mapSamples ?? this.cobeOptions.mapSamples;
    this.cobeOptions.mapBrightness = options.mapBrightness ?? this.cobeOptions.mapBrightness;
    this.cobeOptions.mapBaseBrightness = options.mapBaseBrightness ?? this.cobeOptions.mapBaseBrightness;
    this.cobeOptions.baseColor = options.baseColor ?? this.cobeOptions.baseColor;
    this.cobeOptions.markerColor = options.markerColor ?? this.cobeOptions.markerColor;
    this.cobeOptions.glowColor = options.glowColor ?? this.cobeOptions.glowColor;
    this.cobeOptions.markers = options.markers ?? this.cobeOptions.markers;
    this.cobeOptions.diffuse = options.diffuse ?? this.cobeOptions.diffuse;
    this.cobeOptions.devicePixelRatio = options.devicePixelRatio ?? this.cobeOptions.devicePixelRatio;
    this.cobeOptions.dark = options.dark ?? this.cobeOptions.dark;
    this.cobeOptions.opacity = options.opacity ?? this.cobeOptions.opacity;
    this.cobeOptions.offset = options.offset ?? this.cobeOptions.offset;
    this.cobeOptions.scale = options.scale ?? this.cobeOptions.scale;
    this.cobeOptions.context = options.context ?? this.cobeOptions.context;
  }
  static {
    this.ɵfac = function NgxGlobeComponent_Factory(t) {
      return new (t || NgxGlobeComponent)(i0.ɵɵdirectiveInject(PLATFORM_ID));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxGlobeComponent,
      selectors: [["om-globe"]],
      viewQuery: function NgxGlobeComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.globeCanvas = _t.first);
        }
      },
      inputs: {
        styleClass: "styleClass",
        rotationSpeed: "rotationSpeed",
        globeOptions: "globeOptions",
        globeCanvasSize: [i0.ɵɵInputFlags.None, "globeSize", "globeCanvasSize"]
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 1,
      consts: [["globeCanvas", ""], [1, "om-globe", 3, "ngStyle"], [1, "om-globe-background"], ["width", "1000", "height", "1000", 3, "pointerdown", "pointerup", "pointerout", "mousemove", "touchmove"]],
      template: function NgxGlobeComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "div", 1)(1, "div", 2)(2, "canvas", 3, 0);
          i0.ɵɵlistener("pointerdown", function NgxGlobeComponent_Template_canvas_pointerdown_2_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.updatePointerInteraction($event.clientX - ctx.pointerInteractionMovement));
          })("pointerup", function NgxGlobeComponent_Template_canvas_pointerup_2_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.updatePointerInteraction(null));
          })("pointerout", function NgxGlobeComponent_Template_canvas_pointerout_2_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.updatePointerInteraction(null));
          })("mousemove", function NgxGlobeComponent_Template_canvas_mousemove_2_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.updateMovement($event.clientX));
          })("touchmove", function NgxGlobeComponent_Template_canvas_touchmove_2_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.updateMovement($event.touches[0].clientX));
          });
          i0.ɵɵelementEnd()()();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngStyle", ctx.style);
        }
      },
      dependencies: [CommonModule, i1.NgStyle],
      styles: [".om-globe[_ngcontent-%COMP%]{--globe-size: 600px;position:relative;width:var(--globe-size);height:var(--globe-size);overflow:hidden}.om-globe[_ngcontent-%COMP%]   .om-globe-background[_ngcontent-%COMP%]{position:absolute;width:100%;max-width:var(--globe-size);aspect-ratio:1/1;margin-left:auto;margin-right:auto;inset:0}.om-globe[_ngcontent-%COMP%]   .om-globe-background[_ngcontent-%COMP%]   canvas[_ngcontent-%COMP%]{width:100%;height:100%;contain:layout paint size;opacity:1;cursor:grab}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxGlobeComponent, [{
    type: Component,
    args: [{
      selector: "om-globe",
      standalone: true,
      imports: [CommonModule],
      template: "<div class=\"om-globe\" [ngStyle]=\"style\">\n    <div class=\"om-globe-background\">\n        <canvas (pointerdown)=\"updatePointerInteraction($event.clientX - pointerInteractionMovement)\"\n            (pointerup)=\"updatePointerInteraction(null)\" (pointerout)=\"updatePointerInteraction(null)\"\n            (mousemove)=\"updateMovement($event.clientX)\" (touchmove)=\"updateMovement($event.touches[0].clientX)\"\n            width=\"1000\" height=\"1000\" #globeCanvas></canvas>\n    </div>\n</div>",
      styles: [".om-globe{--globe-size: 600px;position:relative;width:var(--globe-size);height:var(--globe-size);overflow:hidden}.om-globe .om-globe-background{position:absolute;width:100%;max-width:var(--globe-size);aspect-ratio:1/1;margin-left:auto;margin-right:auto;inset:0}.om-globe .om-globe-background canvas{width:100%;height:100%;contain:layout paint size;opacity:1;cursor:grab}\n"]
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }], {
    globeCanvas: [{
      type: ViewChild,
      args: ["globeCanvas"]
    }],
    styleClass: [{
      type: Input,
      args: ["styleClass"]
    }],
    rotationSpeed: [{
      type: Input,
      args: ["rotationSpeed"]
    }],
    globeOptions: [{
      type: Input,
      args: ["globeOptions"]
    }],
    globeCanvasSize: [{
      type: Input,
      args: ["globeSize"]
    }]
  });
})();

/*
 * Public API Surface of ngx-globe
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxGlobeComponent };
