import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IDetailsFooter } from '../../models/details-footer.model';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { Constants } from '../../tools/constants';

/**
 * @name FooterComponent
 * @description permet la gestion du footer du site icones, liens extenerne, dl de fichiers
 * et inscription à la newsletter
 */
@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  providers: [AppTranslationService],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent {
  linkLegalNotices: string = Constants.pathLegalNotices;
  detailsFooter: IDetailsFooter[] = Constants.detailsFooters;

  @Output() openNewsletter = new EventEmitter<boolean>();

  constructor(
    protected alertService: AlertService,
    protected translate: AppTranslationService) { }

  openModalNewsletter():void{
    this.openNewsletter.emit(true);
  }
}
