import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { IItemPack } from '../../models/item-pack.model';
import { ResponsiveService } from '../../services/responsive.service';
import { Constants } from '../../tools/constants';
import { FarmsNavigationComponent } from '../farms-navigation/farms-navigation.component';
import { FormRequestComponent } from '../form-request/form-request.component';
import { IItemNavigation } from '../../models/item-navigation.model';
import { forEach } from 'lodash';

/**
 * @name FarmsPacksComponent
 * @description permet l'affichage des packs farms existants via les composants suivants
 * FarmsNavigationComponent => gestion de la navigation et du scroll
 * FormRequestComponent => la gestion du formulaire de contact
 * l'affiche se décompose de 4 packs avec hover et au clique l'affiche des avantages proposés
 */
@Component({
  selector: 'app-farms-packs',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterLink, FarmsNavigationComponent, FormRequestComponent],
  templateUrl: './farms-packs.component.html',
  styleUrl: './farms-packs.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class FarmsPacksComponent implements OnInit {

  @ViewChild("scrollContact")
  scrollContact!: ElementRef

  @ViewChild("scrollPacks")
  scrollpacks!: ElementRef;

  logoFarms: string = '/assets/images/logo_farms_solution.png';

  itemsPack: IItemPack[] = Constants.itemsPack;
  currentLocale: string;
  currentIndex = 0;
  currentPack: IItemPack = this.itemsPack[this.currentIndex]!;
  urlShadow: string = Constants.shadow;

  codeTitle: string = 'pagePacks.contact';
  type: string = 'packs'
  repository: string = 'replies';
  pathContact: string = Constants.pathContact;
  itemsNagivation: IItemNavigation[] = Constants.itemNavigationsBarre;

  constructor(private translate: TranslateService, public responsive: ResponsiveService) {
    this.currentLocale = this.translate.currentLang;
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLocale = event.lang;
    });
  }

  ngOnInit(): void {
    forEach(this.itemsNagivation, item=>{
      item.open = item.link == '/solutions/farms-packs';
    });
  }

  /**
   * @name onChangePack
   * @memberof FarmsPacksComponent
   * @param indexPack
   * @description permet au click d'un pack de changer l'index current pour afficher ces avantages
   */
  onChangePack(indexPack: number): void {
    this.currentIndex = indexPack;
    this.currentPack = this.itemsPack[this.currentIndex];
  }

  /**
   * @name setScroll
   * @memberof FarmsPacksComponent
   * @param $event
   * @description permet la reception l'event du composant navigation pour efectuer un scroll sur un element
   */
  setScroll($event: any): void {
    let contenairScroll: ElementRef = $event.scroll == 'bottom' ? this.scrollContact : this.scrollpacks;
    contenairScroll.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

}
