<app-farms-navigation [tabNavigation]="itemsNavigation" (setScroll)="setScroll($event)"></app-farms-navigation>
<div class="containt-about" #scrollHistory>
    <!-- CONTAINT HISTORY -->
    <div class="containt-history"
        [ngClass]="{'xs-view': responsive.isXsView, 'sm-view': responsive.isSmView, 'md-view': responsive.isMdView}">
        <div class="title-master" [ngClass]="{'xs-view': responsive.isXsView,}">{{'pageAboutUs.title_history' |
            translate}}</div>

        <div class="containt-timeline">
            <div class="timeline"></div>
            <app-item-history *ngFor="let itemH of itemsHistory" [itemHistory]="itemH"></app-item-history>
        </div>

    </div>
    <!-- CONTAINT PRESENCE -->
    <div class="containt-presence" #scrollPresence
        [ngClass]="{'xs-view': responsive.isXsView, 'sm-view': responsive.isSmView, 'md-view': responsive.isMdView}">
        <div class="title-master" [ngClass]="{'xs-view': responsive.isXsView}">{{'pageAboutUs.title_presence' |
            translate}}</div>

        <div class="containt-master-pays"
            [ngClass]="{'xs-view': responsive.isXsView || responsive.isSmView || responsive.isMdView}">
            <om-globe [globeSize]="500" [globeOptions]="globeOptions"
                [ngClass]="{'xs-view': responsive.isXsView || responsive.isSmView || responsive.isMdView  }"></om-globe>
            <div class="containt-pays" [ngClass]="{'xs-view': responsive.isXsView || responsive.isSmView || responsive.isMdView}">

                <div class="card-pays">
                    <div class="title-pays">
                        <img class="img-pays" src="./assets/images/cameroun.png" alt="icone logo">
                        {{'pageAboutUs.cameroun' | translate}}
                    </div>
                    <div class="liste-pays">
                        <div>PLANTATION DU HAUT PENJA</div>
                        <div>CDBM</div>
                    </div>

                </div>

                <div class="card-pays">
                    <div class="title-pays">
                        <img class="img-pays" src="./assets/images/cote_ivoire.png" alt="icone logo">
                        {{'pageAboutUs.cote_ivoire' | translate}}
                    </div>
                    <div class="liste-pays">
                        <div>SOCIÉTÉ CULTURE BANANIÈRE</div>
                        <div>BBSA</div>
                        <div>BEOUMI</div>
                        <div>OPAV NORD</div>
                        <div>OPAV SUD</div>
                    </div>
                </div>

                <div class="card-pays">
                    <div class="title-pays">
                        <img class="img-pays" src="./assets/images/equateur.png" alt="icone logo">
                        {{'pageAboutUs.equateur' | translate}}
                    </div>
                    <div class="liste-pays">
                        <div>DEROSE</div>
                    </div>
                </div>
                <div class="card-pays">
                    <div class="title-pays">
                        <img class="img-pays" src="./assets/images/ghana.png" alt="icone logo">
                        {{'pageAboutUs.ghana' | translate}}
                    </div>
                    <div class="liste-pays">
                        <div>GOLDEN EXOTIC LTD</div>
                        <div>GOLD ORGANIC LTD</div>
                    </div>
                </div>
                <div class="card-pays">
                    <div class="title-pays">
                        <img class="img-pays" src="./assets/images/honduras.png" alt="icone logo">
                        {{'pageAboutUs.honduras' | translate}}
                    </div>
                    <div class="liste-pays">
                        <div>AGRICOLA INALMA</div>
                    </div>
                </div>
                <div class="card-pays">
                    <div class="title-pays">
                        <img class="img-pays" src="./assets/images/rep_dom.png" alt="icone logo">
                        {{'pageAboutUs.rep_dom' | translate}}
                    </div>
                    <div class="liste-pays">
                        <div>SAVID DOMINICANA</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <img class="img-presence" src="{{pathPresence}}" alt="image presence mondiale"> -->


    </div>
</div>