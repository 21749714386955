import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HeaderComponent } from './components/header/header.component';

import { RouterModule } from '@angular/router';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { FooterComponent } from './components/footer/footer.component';
import { AlertCommand, AlertService, MessageSeverity } from './services/alert.service';

export interface ToastOptions {
  title: string;
  message?: string;
};

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule,
    TranslateModule,
    HeaderComponent,
    ToastrModule,
    FooterComponent,
    RouterModule],
  providers:[AlertService,ToastrService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  encapsulation:ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  @ViewChild('#contactComponent') private contact!: ElementRef;

  title = 'farms';
  stickyToasties: number[] = [];

  constructor(private translate: TranslateService, protected alertService: AlertService,
    private toastrService: ToastrService) { }

  ngOnInit() {

    this.alertService.getMessageEvent().subscribe(message => this.showToast(message));
    const browserLanguage = this.translate.getBrowserLang();

    const language = browserLanguage?.match(/en|fr|es/)
      ? browserLanguage
      : 'en';

    this.translate.use(language);
  }

  showToast(alert: AlertCommand): void {
    if (alert.operation === 'clear') {
      for (const id of this.stickyToasties.slice(0)) {
        this.toastrService.clear(id);
      }
      return;
    }

    const toastOptions: ToastOptions = {
      title: alert == undefined || alert.message == undefined ? '' : alert.message.summary,
      message: alert == undefined || alert.message == undefined ? '' : alert.message.detail,
    };

    if (alert.operation === 'add_sticky') {
      this.toastrService.toastrConfig.timeOut = 0;
    } else {
      this.toastrService.toastrConfig.timeOut = 4000;
    }

    if (alert != undefined && alert.message != undefined) {
      switch (alert.message.severity) {
        case MessageSeverity.info: this.toastrService.info(toastOptions.message, toastOptions.title); break;
        case MessageSeverity.success: this.toastrService.success(toastOptions.message, toastOptions.title); break;
        case MessageSeverity.error: this.toastrService.error(toastOptions.message, toastOptions.title); break;
        case MessageSeverity.warn: this.toastrService.warning(toastOptions.message, toastOptions.title); break;
      }
    }
  }
}