import { Injectable } from '@angular/core';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged } from 'rxjs/operators';


/**
 * @name ResponsiveService
 * @description service qui facile la gestion du responsive grace à ses nombreux breackpoints,
 * possibilité d'en observer d'avantage en fonction du besion dans la const Breakpoints
 * Utilisation assez simple que ça soit combiné avec:
 * *ngIf='responsive.isXsView' : l'élement ne s'affichera que si on est sur des breakPoint de type mobile
 * [ngClass]="{'d-none': responsive.isXsView || responsive.isMdView'}" : le plus intérassant est la possibilité de
 * combiner les breakPoints du service avec des classes css permettant à un element d'avoir un affchage complétement
 * different en fonctio du type d'écran
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {


  //(max-width: 599.98px) and (orientation: portrait)
  public isXsView: boolean = false;

  //(min-width: 600px) and (max-width: 959.98px)
  public isSmView: boolean = false;

  //(min-width: 960px) and (max-width: 1279.98px)
  public isMdView: boolean = false;

  //(min-width: 1280px) and (orientation: landscape)
  public isWebView: boolean = false;

  // mobile landScape
  public isHandsetLandscape: boolean = false;

  breakpoint$ = this.breakpointObserver

    .observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.WebLandscape,
      Breakpoints.HandsetLandscape
    ])

    .pipe(distinctUntilChanged());


  constructor(public breakpointObserver: BreakpointObserver) {

    this.breakpoint$.subscribe((result) => {

      this.isXsView = result.breakpoints[Breakpoints.XSmall];
      this.isSmView = result.breakpoints[Breakpoints.Small];
      this.isMdView = result.breakpoints[Breakpoints.Medium];
      this.isWebView = result.breakpoints[Breakpoints.WebLandscape];
      this.isHandsetLandscape = result.breakpoints[Breakpoints.HandsetLandscape];

    });

  }

}