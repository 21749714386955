<div class="containt-menus" [ngClass]="{'gap-item':responsive.isMdView && !isOpen , 'horizontal': isOpen && responsive.isXsView}">
    <a [routerLink]="menu.route"
        routerLinkActive="active-link"
        class="containt-menu-item"
        *ngFor="let menu of menus"
        (click)="onCloseAfterRouting()"
        [ngClass]="{'horizontal': isOpen && responsive.isXsView}">

        <div class="containt-icon-menu" [ngClass]="{'horizontal': isOpen && responsive.isXsView}">
            <img class="icon-item-menu"
            src="{{menu.icon}}"
            alt="icon-menu"
            routerLinkActive="active-link"
            [ngClass]="{'horizontal': isOpen && responsive.isXsView}">
        </div>
       <span [ngClass]="{'d-none':((responsive.isSmView || responsive.isMdView) && !isOpen) || (!responsive.isXsView && isOpen)}">{{menu.code | translate}}</span></a>
</div>
