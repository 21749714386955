<div class="containt-footer">
    <div class="detail-footer" *ngFor="let detail of detailsFooter">

        <img *ngIf="detail.iconTitle != null" class="icon-title" alt="icone footer" src="{{detail.iconTitle}}" />

        <div *ngIf="detail.type != 'link'" class="title-detail" [ngClass]="{'link-media': detail.type == 'icon-media'}">
            {{ detail.title | translate}}
            <span *ngIf="detail.content != null"><span class="me-2">:</span>{{detail.content}}</span>
        </div>

        <div class="icon-media-item" *ngIf="detail.icons != null">
            <a *ngFor="let icon of detail.icons" href="{{icon.link}}" target="_blank">
                <img class="icon-title" alt="icone footer" src="{{icon.path}}" />
            </a>
        </div>

        <div *ngIf="detail.type == 'email'" class="d-flex"> <input type="email" id="email-newsletter"
                name="email-newsletter" class="form-control email-content" [(ngModel)]="emailModel" />
            <div (click)="onSubmit()" class="btn-newsletter">{{ 'footer.souscribe' | translate}}</div>
        </div>

        <a *ngIf="detail.type == 'link'" href="{{linkLegalNotices}}" class="title-detail link" target="_blank">{{
            detail.title | translate}}</a>
    </div>
</div>