<div class="card-indicator"
    [ngClass]="{'xs-view': responsive.isXsView, 'sm-view': responsive.isSmView, 'md-view': responsive.isMdView, 'web-view': responsive.isWebView}">
    <div class="containt-icon">
        <img src="{{indicator.icon}}" alt="icone d'un indicateur">
    </div>
    <div class="content-indicator">
        {{ indicator.code | translate }}
    </div>
</div>
<div class="clone-card-1 {{indicator.prefixeClass}}-clone-1"
    [ngClass]="{'xs-view': responsive.isXsView, 'sm-view': responsive.isSmView, 'md-view': responsive.isMdView, 'web-view': responsive.isWebView}">
</div>
<div class=" clone-card-2 {{indicator.prefixeClass}}-clone-2"
    [ngClass]="{'xs-view': responsive.isXsView, 'sm-view': responsive.isSmView, 'md-view': responsive.isMdView, 'web-view': responsive.isWebView}">
</div>