import { Routes } from '@angular/router';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { AskForDemoComponent } from './components/ask-for-demo/ask-for-demo.component';
import { ContactComponent } from './components/contact/contact.component';
import { FarmsApplicationComponent } from './components/farms-application/farms-application.component';
import { FarmsPacksComponent } from './components/farms-packs/farms-packs.component';
import { HomeComponent } from './components/home/home.component';
import { OfferingComponent } from './components/offering/offering.component';
import { SolutionsComponent } from './components/solutions/solutions.component';

export const routes: Routes = [
    { path: "home", component: HomeComponent },
    { path: "about-us", component: AboutUsComponent },
    { path: "offering", component: OfferingComponent },
    { path: "solutions", component: SolutionsComponent },
    { path: "solutions/farms-application", component: FarmsApplicationComponent },
    { path: "solutions/farms-packs", component: FarmsPacksComponent },
    { path: "contact", component: ContactComponent },
    { path: "ask-for-demo", component: AskForDemoComponent },
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: '**', redirectTo: 'home', pathMatch: 'full' },
];