<div class="containt-item-history"
    [ngClass]="{'xs-view': responsive.isXsView || responsive.isSmView  || responsive.isMdView}">

    <div class="history-year" [ngClass]="{'order-1': itemHistory.visualFirst, 'order-2': !itemHistory.visualFirst}">
        <div class="date-history">{{itemHistory.date}}</div>
        <div *ngIf="itemHistory.endDate != null" class="date-end-history">{{itemHistory.endDate}}</div>
    </div>
    <div class="containt-history-body" [ngClass]="{'order-1': !itemHistory.visualFirst && !responsive.isXsView && !responsive.isSmView && !responsive.isMdView, 
        'order-2': itemHistory.visualFirst || responsive.isXsView || responsive.isSmView || responsive.isMdView, 
        'xs-view':  responsive.isXsView, 
        'sm-view': responsive.isSmView,
        'md-view': responsive.isMdView}">


        <div class="containt-text"
            [ngClass]="{'order-1': !itemHistory.visualFirst && !responsive.isXsView && !responsive.isSmView && !responsive.isMdView,'order-2': itemHistory.visualFirst  || responsive.isXsView || responsive.isSmView || responsive.isMdView,'xs-view':  responsive.isXsView,'sm-view': responsive.isSmView,'md-view': responsive.isMdView}">
            <div class="title-history">
                {{itemHistory.title | translate}}
            </div>
            <div *ngIf="itemHistory.content != null" [innerHTML]="itemHistory.content! | translate"></div>

            <img class="card-content-img" *ngIf="itemHistory.contentImg !=null"
                [ngClass]="{'order-1': !itemHistory.visualFirst, 'order-2': itemHistory.visualFirst}"
                src="{{itemHistory.contentImg}}" alt="image timeline">
        </div>


        <div class="containt-img"
            [ngClass]="{'order-1': itemHistory.visualFirst || responsive.isXsView || responsive.isSmView || responsive.isMdView, 'order-2': !itemHistory.visualFirst && !responsive.isXsView && !responsive.isSmView && !responsive.isMdView,'xs-view': responsive.isXsView || responsive.isSmView || responsive.isMdView}">

            <img src="{{itemHistory.image}}" alt="image timeline">
        </div>
    </div>

</div>