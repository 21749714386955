import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IItemCardApplication } from '../../models/item-card-application.model';
import { ResponsiveService } from '../../services/responsive.service';
import { Constants } from '../../tools/constants';
import { CardApplicationComponent } from '../card-application/card-application.component';
import { FarmsNavigationComponent } from '../farms-navigation/farms-navigation.component';
import { FormRequestComponent } from '../form-request/form-request.component';

/**
 * @name FarmsApplicationComponent
 * @description permet la gestion de l'affichage de la page Application Farms avec les composants suivants
 * FarmsNavigationComponent => pour la gestion de navigation et scroll via un outPut
 * CardApplicationComponent => pour la gestion de l'affichage des applicatits farms
 * FormRequestComponent => pour l'affichage du formulaire
 */
@Component({
  selector: 'app-farms-application',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterLink, FarmsNavigationComponent, CardApplicationComponent, FormRequestComponent],
  templateUrl: './farms-application.component.html',
  styleUrl: './farms-application.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class FarmsApplicationComponent {

  @ViewChild("scrollContact")
  scrollContact!: ElementRef;

  @ViewChild("scrollApplication")
  scrollApplication!: ElementRef;

  logoFarms: string = '/assets/images/logo_farms_solution.png';
  urlConstellation: string = '/assets/images/visuel_farms_constellation.jpg';
  itemsCardApplication: IItemCardApplication[] = Constants.itemsCardApplication;
  codeTitle: string = 'pageApp.title_contact';
  type: string = 'contact'
  repository: string = 'replies';
  pathContact: string = Constants.pathContact;

  constructor(protected responsive: ResponsiveService) { }

  /**
   * @name setScroll
   * @memberof FarmsApplicationComponent
   * @param $event
   * @description permet la reception l'event du composant navigation pour efectuer un scroll sur un element
   */
  setScroll($event: any): void {
    let contenairScroll: ElementRef = $event.scroll == 'bottom' ? this.scrollContact : this.scrollApplication;
    contenairScroll.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
