<app-farms-navigation [tabNavigation]="itemsNagivation" (setScroll)="setScroll($event)"></app-farms-navigation>
<div class="containt-application"
    [ngClass]="{'xs-view': responsive.isXsView, 'sm-view': responsive.isSmView, 'md-view': responsive.isMdView}">
    <div class="containt-main-application">
        <div class="containt-logo">
            <img [ngClass]="{'xs-view': responsive.isXsView, 'sm-view': responsive.isSmView}" class="logo-farms" src="{{logoFarms}}" alt="icone logo">
            <img [ngClass]="{'xs-view': responsive.isXsView, 'sm-view': responsive.isSmView}" class="constellation-farms" src="{{urlConstellation}}" alt="constellation farms">
        </div>

        <div class="title-master">{{'pageSolutions.title_farms' | translate}}</div>
        <div class="sub-title-master">{{'pageFarms.sub_title' | translate}}</div>
        <div class="content-master" [innerHtml]="'pageFarms.main_content' | translate"></div>
    </div>

    <div class="containt-module-app">
        <app-card-application *ngFor="let app of itemsCardApplication" [app]="app">
        </app-card-application>
    </div>
</div>