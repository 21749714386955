import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ILanguage } from '../../models/language.model';
import { ResponsiveService } from '../../services/responsive.service';
import { Constants } from '../../tools/constants';

/**
 * @name LanguageComponent
 * @description Composant permettant la gestion de langue avec des icones
 * au click grace à la méthode onChangeLanguage. Gère aussi l'affichage différent en responsive
 * avec un dropdown
 */
@Component({
  selector: 'app-language',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './language.component.html',
  styleUrl: './language.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class LanguageComponent implements OnInit {

  languages: ILanguage[] = Constants.languages;
  currentLanguage!: string;

  @Input() dropdown!: boolean;

  constructor(private translate: TranslateService, public responsive: ResponsiveService) { }

  /**
   * @name ngOnInit
   * @memberof LanguageComponent
   * @description initialisation
   */
  ngOnInit() {
    this.currentLanguage = this.translate.currentLang;
  }

  /**
   * @name onChangeLanguage
   * @memberof LanguageComponent
   * @param locale selectionnée suis au click
   * @description permet de changer la locale
   */
  onChangeLanguage(locale: string): void {
    this.currentLanguage = locale;
    this.translate.use(locale);
  }
}
