import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IMenu } from '../../models/menu.model';
import { ResponsiveService } from '../../services/responsive.service';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [CommonModule,
    TranslateModule,
    RouterLink,
    RouterOutlet,
    RouterLinkActive],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent {

  @Input() menus!: IMenu[];
  @Input() isOpen!: boolean;
  @Output() closeModal = new EventEmitter<boolean>()

  constructor(public responsive: ResponsiveService) { }

  /**
   * @name onCloseAfterRouting
   * @memberof MenuComponent
   * @description permet à la sélection du routing de remonter l'information au parent de close
   * la modal grace à l'event
   */
  onCloseAfterRouting(): void {
    this.closeModal.emit(false);
  }
}
