import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IItemHistory } from '../../models/item-history.model';
import { ResponsiveService } from '../../services/responsive.service';

/**
 * @name ItemHistoryComponent
 * @description permet l'affichage texte et image en fonction de l'objet itemHistory recu en Input
 * cela permet gerer l'order des div internes du composant et de son responsive
 */
@Component({
  selector: 'app-item-history',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './item-history.component.html',
  styleUrl: './item-history.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ItemHistoryComponent {

  @Input() itemHistory!: IItemHistory;

  constructor(protected responsive: ResponsiveService) { }

}
