import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IDetailsFooter } from '../../models/details-footer.model';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { UploadAwsService } from '../../services/upload-aws.service';
import { Constants } from '../../tools/constants';

/**
 * @name FooterComponent
 * @description permet la gestion du footer du site icones, liens extenerne, dl de fichiers
 * et inscription à la newsletter
 */
@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  providers: [AppTranslationService],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent {
  linkLegalNotices: string = Constants.pathLegalNotices;
  detailsFooter: IDetailsFooter[] = Constants.detailsFooters;
  emailModel: string = '';
  regexEmail: RegExp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,5}$/;

  constructor(private readonly serviceAws: UploadAwsService,
    protected alertService: AlertService,
    protected translate: AppTranslationService) { }

  /**
   * @name onSubmit
   * @memberof FooterComponent
   * @description permet de vérifier la validité du champ email dans le footer au click
   * si c'est valide on peut call upload en utilisant le service AWS et informer l'utilisateur
   * sinon on informe que l'email saisi n'est pas conforme
   */
  onSubmit(): void {
    if (this.regexEmail.test(this.emailModel)) {
      const params = { email: this.emailModel, type: 'newsletters' };
      this.serviceAws.uploadFile(JSON.stringify(params), 'newsletters');
      this.alertService.showMessage(this.translate.getTranslation('commons.message'), this.translate.getTranslation('footer.email_send'), MessageSeverity.success);
      this.emailModel = '';
    } else {
      this.alertService.showMessage(this.translate.getTranslation('commons.message'), this.translate.getTranslation('footer.email_error'), MessageSeverity.info);
    }
  }
}
