import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IItemHistory } from '../../models/item-history.model';
import { ResponsiveService } from '../../services/responsive.service';
import { Constants } from '../../tools/constants';
import { ItemHistoryComponent } from '../item-history/item-history.component';

/**
 * @name AboutUsComponent
 * @description composant qui permet la gestion de l'écran About us avec l'injection du composant
 * ItemHistoryComponent, cela permet d'afficher les données en bouclant sur ce composant qui contient
 * image text. Permet aussi la gestion de la navigation des anchors aavec un effet smooth
 */
@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [CommonModule, TranslateModule, ItemHistoryComponent],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class AboutUsComponent {

  itemsHistory: IItemHistory[] = Constants.detailsHistory;
  pathPresence: string = Constants.pathPresence;
  shadow: string = Constants.shadow;
  finalHistory: string = Constants.finalHistory;
  scrollUp: string = Constants.scrollUp;

  @ViewChild("scrollHistory")
  scrollHistory!: ElementRef;

  @ViewChild("scrollPresence")
  scrollPresence!: ElementRef;

  constructor(protected responsive: ResponsiveService) { }

  /**
   * @name goToScrollNavigation
   * @memberof AboutUsComponent
   * @param contener nom du container sur lequel on veut scroll
   * @description permet au clique de scroll d'un élément à un autre avec un effet smooth
   */
  goToScrollNavigation(contener: string): void {
    let contenairScroll: ElementRef = contener == 'history' ? this.scrollHistory : this.scrollPresence;
    contenairScroll.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
