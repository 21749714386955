import { Component } from '@angular/core';
import { Constants } from '../../tools/constants';
import { FormRequestComponent } from '../form-request/form-request.component';

/**
 * @name ContactComponent
 * @description omponenent passe-plat pour un form-request avec initialisation de variables néccessaires
 */
@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [FormRequestComponent],
  templateUrl: './contact.component.html'
})
export class ContactComponent {
  codeTitle: string = 'pageHeader.contact';
  type: string = 'contact'
  repository: string = 'replies';
  pathContact: string = Constants.pathContact;
}
