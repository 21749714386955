import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ResponsiveService } from '../../services/responsive.service';
import { Constants } from '../../tools/constants';
import { IItemNavigation } from '../../models/item-navigation.model';
import { forEach } from 'lodash';

@Component({
  selector: 'app-farms-navigation',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterLink],
  templateUrl: './farms-navigation.component.html',
  styleUrl: './farms-navigation.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class FarmsNavigationComponent {

  @Input() tabNavigation!:IItemNavigation[];
  shadow: string = Constants.shadow;
  @Output() setScroll = new EventEmitter<{ scroll: string }>();

  constructor(protected responsive: ResponsiveService) { }

  /**
   * @name goToScrollNavigation
   * @memberof FarmsNavigationComponent
   * @param contener nom du container sur lequel on veut scroll
   * @description permet au clique de scroll d'un élément à un autre avec un effet smooth
   */
  goToScrollNavigation(contener: string): void {
    this.setScroll.emit({ scroll: contener })
  }

  /**
   * @name onChangeOnglet
   * @memberof FarmsNavigationComponent
   * @description permet d'appliquer une clase active en fonction de la propriété open
   */
  onChangeOnglet(title:string):void{
    forEach(this.tabNavigation, (item:IItemNavigation)=>{
      let isSame = title == item.title;
      item.open = isSame;
      if(isSame && item.scrollCode != null){
        this.goToScrollNavigation(item.scrollCode);
      }
    });
  }
}