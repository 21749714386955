import { Component, Input, ViewEncapsulation } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ICardProduct } from '../../models/card-product.model';

/**
 * @name CardProductComponent
 * @description Permet l'affichage des produit de digital farms avec un logo, title, un content et un link
 */
@Component({
  selector: 'app-card-product',
  standalone: true,
  imports: [TranslateModule, RouterLink, RouterOutlet, RouterLinkActive],
  templateUrl: './card-product.component.html',
  styleUrl: './card-product.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class CardProductComponent {
  @Input() itemProduct!: ICardProduct;
}
