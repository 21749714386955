import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ICardProduct } from '../../models/card-product.model';
import { ResponsiveService } from '../../services/responsive.service';
import { Constants } from '../../tools/constants';
import { CardProductComponent } from '../card-product/card-product.component';

@Component({
  selector: 'app-solutions',
  standalone: true,
  imports: [CommonModule, TranslateModule, CardProductComponent, RouterLink],
  templateUrl: './solutions.component.html',
  styleUrl: './solutions.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class SolutionsComponent {
  itemsCardProduct: ICardProduct[] = Constants.itemsCardProduct;

  constructor(protected responsive: ResponsiveService) {}
}
