import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HeaderComponent } from './components/header/header.component';

import { RouterModule } from '@angular/router';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { FooterComponent } from './components/footer/footer.component';
import { AlertCommand, AlertService, MessageSeverity } from './services/alert.service';
import { UploadAwsService } from './services/upload-aws.service';
import { FormsModule } from '@angular/forms';
import { AppTranslationService } from './services/app-translation.service';
import { ResponsiveService } from './services/responsive.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';

export interface ToastOptions {
  title: string;
  message?: string;
};

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule,
    TranslateModule,
    HeaderComponent,
    ToastrModule,
    FooterComponent,
    RouterModule,
    FormsModule],
  providers: [AlertService, ToastrService, AppTranslationService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit {

  @ViewChild('#contactComponent') private contact!: ElementRef;

  title = 'farms';
  stickyToasties: number[] = [];
  isOpenMenu: boolean = false;
  showNewsletter: boolean = false;
  acceptData: boolean = false;
  wizzClass: string = '';

  emailModel: string = '';
  regexEmail: RegExp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,5}$/;

  constructor(private translate: TranslateService,
    private translation: AppTranslationService,
    protected alertService: AlertService,
    private toastrService: ToastrService,
    private readonly serviceAws: UploadAwsService,
    public response: ResponsiveService,
    private router: Router) { }

  ngOnInit() {

    this.alertService.getMessageEvent().subscribe(message => this.showToast(message));
    const browserLanguage = this.translate.getBrowserLang();

    const language = browserLanguage?.match(/en|fr|es/)
      ? browserLanguage
      : 'en';

    this.translate.use(language);
  }

  ngAfterViewInit() {
    // Écoute des changements de route et remet le scroll à zéro
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const masterContainer = document.querySelector('.master-container');
      if (masterContainer) {
        masterContainer.scrollTop = 0;  // Remet le scroll à 0
      }
    });
  }

  showToast(alert: AlertCommand): void {
    if (alert.operation === 'clear') {
      for (const id of this.stickyToasties.slice(0)) {
        this.toastrService.clear(id);
      }
      return;
    }

    const toastOptions: ToastOptions = {
      title: alert == undefined || alert.message == undefined ? '' : alert.message.summary,
      message: alert == undefined || alert.message == undefined ? '' : alert.message.detail,
    };

    if (alert.operation === 'add_sticky') {
      this.toastrService.toastrConfig.timeOut = 0;
    } else {
      this.toastrService.toastrConfig.timeOut = 4000;
    }

    if (alert != undefined && alert.message != undefined) {
      switch (alert.message.severity) {
        case MessageSeverity.info: this.toastrService.info(toastOptions.message, toastOptions.title); break;
        case MessageSeverity.success: this.toastrService.success(toastOptions.message, toastOptions.title); break;
        case MessageSeverity.error: this.toastrService.error(toastOptions.message, toastOptions.title); break;
        case MessageSeverity.warn: this.toastrService.warning(toastOptions.message, toastOptions.title); break;
      }
    }
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event: any) {
    if (this.isOpenMenu) {
      this.isOpenMenu = false;
    }
  }

  changeValueMenuOpen($event: boolean) {
    this.isOpenMenu = $event;
  }

  openNewsletter($event: boolean): void {
    this.showNewsletter = $event;
  }

  closeModal(): void {
    this.showNewsletter = false;
  }

  /**
   * @name onSubmit
   * @memberof AppComponent
   * @description permet de vérifier la validité du champ email dans le footer au click
   * si c'est valide on peut call upload en utilisant le service AWS et informer l'utilisateur
   * sinon on informe que l'email saisi n'est pas conforme
   */
  onSubmit(): void {
    if (!this.acceptData) {
      this.onStartAnimation('wizz', 300, true);
      return;
    }
    if (this.regexEmail.test(this.emailModel)) {
      const params = { email: this.emailModel, type: 'newsletters' };
      this.serviceAws.uploadFile(JSON.stringify(params), 'newsletters');
      this.alertService.showMessage(this.translation.getTranslation('commons.message'), this.translation.getTranslation('footer.email_send'), MessageSeverity.success);
      this.emailModel = '';
      this.showNewsletter = false;
    } else {
      this.alertService.showMessage(this.translation.getTranslation('commons.message'), this.translation.getTranslation('footer.email_error'), MessageSeverity.info);
    }
  }

  /**
   * @name onStartAnimation
   * @memberof AppComponent
   * @description permet de lancer une animation sur un contenair
   */
  onStartAnimation(animation: string, timer: number, restart?: boolean): void {
    this.wizzClass = animation;
    if (restart) {
      setTimeout(() => {
        this.wizzClass = '';
      }, timer);
    }
  }
}